import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "100%",
      backgroundColor: "#18181B",
      marginLeft: "0",
      display: "flex",
      alignItems: "center",
    },
    mainGrid: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        alignItems: "flex-start",
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/mainPageBackgroundImg.png"
        })`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        padding: "24px",
        minHeight: "21%",
      },
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/mainPageBackgroundImg.png"
        })`,
        backgroundSize: "100% 110%",
        backgroundRepeat: "no-repeat",
        minHeight: "11%",
      },
      display: "flex",
      justifyContent: "flex-end",
    },
    companyNameImg: {
      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
        height: "13rem",
        width: "20rem",
        alignSelf: "center",
      },
      height: "15rem",
      marginRight: "6rem",
    },
    grid: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingLeft: "0!important",
      paddingTop: "0!important",
      marginBottom: "2rem",
    },
    gridLeftAlign: {
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
      },
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      paddingLeft: "3%!important",
      paddingTop: "0!important",
      marginBottom: "2rem",
    },
    gridRightAlign: {
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
      },
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "column",
      paddingLeft: "0!important",
      paddingRight: "3%!important",
      paddingTop: "0!important",
      marginBottom: "2rem",
    },

    gridCenterAlign: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: "0!important",
      paddingTop: "0!important",
    },
    gridHeaderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "5rem",
    },
    gridHeader: {
      [theme.breakpoints.down("md")]: {
        fontSize: "3.5rem",
        lineHeight: "3.5rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
        lineHeight: "3.5rem",
      },
      color: "#fff",
      fontSize: "5rem",
      lineHeight: "5rem",
      fontWeight: "700",
    },
    gridSubHeaderContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    gridSubHeader: {
      [theme.breakpoints.down("md")]: {
        fontSize: "3rem",
        lineHeight: "3rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.9rem",
        lineHeight: "1.5rem",
      },
      color: "#C8FF6C",
      fontSize: "4rem",
      lineHeight: "4rem",
      marginBottom: "2rem",
      fontWeight: "700",
    },
    gridStepHeader: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "2rem",
        fontSize: "2rem",
      },
      marginTop: "4rem",
      color: "#EFFFD4",
      fontSize: "2.5rem",
      fontWeight: "700",
      marginBottom: "1.5rem",
    },
    gridStepContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "1.5rem",
    },
    gridStep: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.1rem",
      },
      color: "#fff",
      fontSize: "1.2rem",
    },
    lastSectionHeader: {
      [theme.breakpoints.down("md")]: {
        fontSize: "3.5rem",
        lineHeight: "3.5rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
        lineHeight: "3.5rem",
      },
      color: "#C8FF6C",
      fontSize: "4rem",
      lineHeight: "4rem",
    },
    gridLastSectionContainer: {
      marginTop: "2rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    green: {
      color: "#C8FF6C",
    },
    onlyLogoImg: {
      width: "17rem",
      alignSelf: "center",
    },
    embededYoutube: {
      margin: "4rem 0",
      borderWidth: "0",
      maxWidth: "90%",
    },
    divider: {
      [theme.breakpoints.down("sm")]: {
        margin: "3rem 1rem 1rem 1rem",
      },
      borderTop: "solid 0.05rem #71717A",
      color: "#71717A",
      margin: "3rem 2rem 0rem 2rem",
      height: "0",
    },
    subHeading: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.1rem",
      },
      marginTop: "0.5rem",
      marginBottom: "1rem",
      fontSize: "1.3rem",
    },
    text: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      },
      marginTop: "0.3rem",
      fontSize: "0.9rem",
    },
    emailInput: {
      width: "100%",
      backgroundColor: "#fff",
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: 0,
      },
    },
    inputContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "1rem",
        paddingRight: "0rem",
      },
      display: "flex",
      marginTop: "2rem",
      height: "2.5rem",
      width: "100%",
      maxWidth: "30rem",
      paddingRight: "6rem",
      justifyContent: "center",
    },
    errorMessage: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8em",
      },
      fontWeight: "bold",
      fontSize: "1rem",
      marginTop: "0.7rem",
      color: "#DB3D3D",
    },
    mainGridInnerContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "0rem",
        height: "600px",
        justifyContent: "flex-start",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: "3rem",
        justifyContent: "flex-start",
      },
      height: "700px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    mainPageImg: {
      height: "700px",
    },
    mainPageImgGrid: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    startButton: {
      cursor: "pointer",
      color: "#18181B",
      backgroundColor: "#C8FF6C",
      borderRadius: "2rem",
      display: "flex",
      fontSize: "1.5rem",
      alignItems: "center",
      fontWeight: "700",
      padding: "2rem 3rem",
    },
  })
);

export { useStyles };
