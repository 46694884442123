import React, { useEffect } from "react";
import { useStyles } from "./RefundPolicyPage.styles";

function RefundPolicyPage() {
  const classes: any = useStyles();
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 10);
  }, []);
  return (
    <div className={classes.container}>
      <div className={classes.title}>Refund Policy</div>
      <div className={classes.text}>
        Before you start using our services, we want to make sure you understand
        the terms and conditions that govern your use of our website and
        software.
      </div>
      <div className={classes.sectionTitle}>
        Full Refund for Technical Problems or Defective Product:
      </div>
      <div className={classes.text}>
        We value the quality of our platform and products. If you encounter any
        technical problems with our platform or receive a defective product, we
        are committed to providing a full refund. To be eligible for a refund,
        you must contact our customer support team within 24 hours from the date
        of purchase or subscription and provide us with the defective audio file
        along with a detailed explanation of how you've trained the model (if
        applicable). Our team will assess the issue promptly and process the
        refund accordingly.
      </div>
      <div className={classes.sectionTitle}>Exclusion of Celebrity Voices:</div>
      <div className={classes.text}>
        Please note that we do not offer celebrity voices as part of our
        services. By subscribing to our platform, you acknowledge and agree that
        celebrity voices are not available. Consequently, we do not offer
        partial or full refunds based on the unavailability of celebrity voices.
      </div>
      <div className={classes.sectionTitle}>Non-Usage of Subscription:</div>
      <div className={classes.text}>
        We encourage all customers to make the most of their subscriptions. We
        do not offer refunds solely due to the non-usage of your subscription.
        It is your responsibility to utilize the services provided within your
        subscribed plan.
      </div>
      <div className={classes.sectionTitle}>Cancellation Policy:</div>
      <div className={classes.text}>
        If you decide to cancel your subscription, you must do so at least 24
        hours before your next billing cycle begins. Failure to cancel within
        this timeframe will result in non-eligibility for a refund. To cancel
        your subscription, please follow the [cancellation procedure] or contact
        our customer support team for assistance.
      </div>
      <div className={classes.sectionTitle}>Outstanding Invoices:</div>
      <div className={classes.text}>
        In the event that you have any outstanding invoices, it is mandatory to
        settle the payment even if you have unsubscribed from our services. We
        do not offer any refunds or reimbursement for outstanding invoices.
      </div>
      <div className={classes.title}>환불 정책</div>
      <div className={classes.text}>
        이 환불 정책은 Sorisori.ai에서의 환불 규정과 절차를 설명합니다. 당사
        웹사이트의 제품 또는 서비스를 구매함으로써 여러분은 이 정책에 동의하게
        됩니다.
      </div>
      <div className={classes.sectionTitle}>환불 가능 여부</div>
      <div className={classes.text}>
        당사의 제품 또는 서비스는 상황에 따라 환불이 가능할 수 있습니다. 제품
        또는 서비스에 따라 환불 가능 여부와 조건이 다를 수 있습니다.
      </div>
      <div className={classes.sectionTitle}>
        기술 문제 또는 불량 제품에 대한 전액 환불:
      </div>
      <div className={classes.text}>
        저희는 플랫폼과 제품의 품질을 중요하게 여깁니다. 만약 플랫폼에 기술적인
        문제가 있을경우, 전액 환불을 제공하기로 약속드립니다. 환불을 받으시려면
        구매 또는 구독 일로부터 24시간 이내에 고객 지원팀에 연락하고, 불량
        오디오 파일과 모델 훈련 방법에 대한 자세한 설명을 제공해야 합니다
        (해당하는 경우). 저희 팀은 문제를 신속하게 평가하고 환불을 처리할
        것입니다.
      </div>
      <div className={classes.sectionTitle}>
        연예인 및 스트리머 목소리 제외:
      </div>
      <div className={classes.text}>
        저희의 서비스에는 연예인 목소리를 포함하지 않음을 주의해주시기 바랍니다.
        저희 플랫폼 구독을 통해 연예인 목소리를 제공하지 않는다는 점을 인지하고
        동의하게 됩니다. 따라서 연예인 목소리 불가능으로 인한 부분 환불이나 전액
        환불은 제공하지 않습니다.
      </div>
      <div className={classes.sectionTitle}>구독 미사용:</div>
      <div className={classes.text}>
        모든 고객께서는 구독 패키지에 포함된 서비스를 사용을 최대한 활용하시기를
        권장드립니다. 단순히 구독 패키지에 포함된 서비스를 모두 사용하지
        않았다는 이유로 환불을 제공하지 않습니다. 구독 계획 내에서 제공되는
        서비스를 활용하는 것은 여러분의 책임입니다.
      </div>
      <div className={classes.sectionTitle}></div>
      <div className={classes.text}>
        결제: 여러분은 선택한 구독 요금제에 따라 요금이 부과됩니다. 결제는 각
        결제 주기의 시작 시점에 이루어집니다. 네이버페이, 카카오페이, 신용카드
        및 PayPal과 같은 다양한 결제 방법을 사용할 수 있습니다.
      </div>
      <div className={classes.sectionTitle}>해지 정책:</div>
      <div className={classes.text}>
        구독을 해지하려면 다음 결제 주기가 시작되기 최소 24시간 전에 해지해야
        합니다. 이 시간 내에 해지하지 않을 경우 환불 대상에서 제외됩니다. 구독을
        해지하려면 [해지 절차]를 따르거나 고객 지원팀에 연락하여 도움을 받으시기
        바랍니다.
      </div>
      <div className={classes.sectionTitle}>미납 요금:</div>
      <div className={classes.text}>
        미납된 요금이 있는 경우, 저희 서비스에서 구독을 취소하더라도 결제를
        해결하실 의무가 있습니다. 미납 요금에 대한 환불이나 보상은 제공하지
        않습니다.
      </div>
    </div>
  );
}

export default RefundPolicyPage;
