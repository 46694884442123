import * as React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Layout from "./components/layout/Layout";
import MainPage from "./components/pages/MainPage";
import PrivacyPolicyPage from "./components/pages/PrivacyPolicyPage";
import TermsPage from "./components/pages/TermsPage";
import RefundPolicyPage from "./components/pages/RefundPolicyPage";
import "./index.css";

export default function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" Component={MainPage} />
          <Route path="/privacy-policy" Component={PrivacyPolicyPage} />
          <Route path="/terms" Component={TermsPage} />
          <Route path="/refund-policy" Component={RefundPolicyPage} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}
