import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainGrid: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "0",
      },
      maxWidth: "100%",
      marginTop: "4rem",
      marginLeft: "0",
      background: "linear-gradient(180deg, #1e2627 0%, #1b191d 100%);",
    },
    contentGrid: {
      [theme.breakpoints.down("md")]: {
        paddingLeft: "1rem!important",
        paddingRight: "1rem!important",
        maxWidth: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
      display: "flex",
      paddingTop: "0!important",
      paddingLeft: "5rem!important",
      paddingRight: "5rem!important",
      paddingBottom: "2rem!important",
      flex: "1",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    content: {
      cursor: "pointer",
      marginBottom: "0.3rem",
      marginLeft: "1rem",
      minWidth: "7rem",
    },
    divider: {
      borderTop: "solid 0.05rem #71717A",
      color: "#71717A",
    },
    companyInfo: {
      marginRight: "1rem",
      fontSize: "0.7rem",
    },
  })
);

export { useStyles };
