import { createStyles, makeStyles } from "@mui/styles";
const useStyles = makeStyles(() =>
  createStyles({
    main: {
      marginTop: "4rem",
      display: "flex",
      flex: "1",
    },
    container: {
      flex: "1",
      display: "flex",
      flexDirection: "column",
    },
  })
);

export { useStyles };
