import { createTheme } from "@mui/material/styles";

declare module "@mui/system/createTheme/createBreakpoints" {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    tablet: false; // adds the `tablet` breakpoint
    laptop: false;
    desktop: false;
  }
}
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 550,
      md: 913,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {},
});

export default theme;
