import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useStyles } from "./MainPage.styles";

import axios, { AxiosResponse } from "axios";

import { Grid, OutlinedInput, Button, CircularProgress } from "@mui/material";
import companyNameGradation from "../../img/companyNameGradation.png";
import mainPageImg from "../../img/mainPageImg.png";
import onlyLogo from "../../img/onlyLogo.png";
import stepOneImg from "../../img/mainPageStepOneImg.png";
import stepTwoImg from "../../img/mainPageStepTwoImg.png";
import mixingImg from "../../img/mixingImg.png";

function MainPage() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const navigateTo = searchParams.get("navigateTo");
  if (navigateTo) {
    if (navigateTo === "first-divider" || navigateTo === "top") {
      setTimeout(() => {
        const violation = document.getElementById(navigateTo);
        window.scrollTo({
          top: violation?.offsetTop || 0,
          behavior: "smooth",
        });
        searchParams.delete("navigateTo");
        setSearchParams(searchParams);
      }, 0);
    }
  }

  const handleStartButton = () => {
    window.open("https://app.sorisori.ai", "_blank");
  };

  // const onSubmit = () => {
  //   console.log("email");
  //   if (!email) {
  //     setMessage("이메일을 넣어주세요.");
  //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
  //     setMessage("형식에 맞지 않는 이메일 주소입니다. 다시 입력해 주세요.");
  //   } else {
  //     setLoading(true);
  //     axios({
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "text/plain",
  //       },
  //       url: "https://7zhhgdkppe.execute-api.us-east-2.amazonaws.com/betaSignup ",
  //       data: email,
  //     })
  //       .then((response: AxiosResponse) => {
  //         if (response.status === 200) {
  //           setMessage("등록완료!");
  //         } else {
  //           setMessage("다시 시도해 주세요.");
  //         }
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // };
  const classes: any = useStyles();
  return (
    <Grid container id="top" className={classes.container} spacing={3}>
      <Grid item xs={12} sm={12} md={5} className={classes.mainGrid}>
        <div className={classes.mainGridInnerContainer}>
          <img
            className={classes.companyNameImg}
            src={companyNameGradation}
            alt="Company name gradation"
          />
          <div className={classes.subHeading}>
            &#8212; 쉽고 간편한 AI커버 제작 플랫폼
          </div>
          <div className={classes.inputContainer}>
            <div onClick={handleStartButton} className={classes.startButton}>
              시작하기
            </div>
          </div>
          {message && <div className={classes.errorMessage}>{message}</div>}
        </div>
      </Grid>
      <Grid item xs={12} sm={7} className={classes.mainPageImgGrid}>
        <img
          className={classes.mainPageImg}
          src={mainPageImg}
          alt="Main page img"
        />
      </Grid>
      <Grid
        id="first-divider"
        className={classes.divider}
        item
        xs={12}
        sm={12}
        md={12}
      />
      <Grid className={classes.grid} item xs={12} sm={12} md={12}>
        <img
          className={classes.onlyLogoImg}
          src={onlyLogo}
          alt="only logo img"
        />
        <div className={classes.gridHeaderContainer}>
          <div className={classes.gridHeader}>이 세상 모든 목소리로</div>
          <div className={classes.gridHeader}>나만의 커버를 제작해보세요!</div>
        </div>
        <div className={classes.gridSubHeaderContainer}>
          <div className={classes.gridSubHeader}>소리소리AI만의</div>
          <div className={classes.gridSubHeader}>특별하고 간편한 제작과정</div>
        </div>
        <iframe
          width="560"
          height="315"
          className={classes.embededYoutube}
          src="https://www.youtube.com/embed/Fksv08A3-dE?si=TzaPV7NU0qqQ9mEB"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen={true}
        ></iframe>
      </Grid>
      <Grid className={classes.gridRightAlign} item xs={12} sm={12} md={6}>
        <div className={classes.gridCenterAlign}>
          <div className={classes.gridStepHeader}>1단계: 음성 모델 제작</div>

          <div className={classes.gridStepContainer}>
            <div className={classes.gridStep}>
              <span className={classes.green}>소리소리AI</span>의 인공지능은
              특별합니다
            </div>
          </div>

          <div className={classes.gridStepContainer}>
            <div className={classes.gridStep}>단 15분의 음원만 있다면</div>
            <div className={classes.gridStep}>빠르고 정확하게 학습 합니다</div>
          </div>
          <div className={classes.gridStepContainer}>
            <div className={classes.gridStep}>
              본인의 목소리를 포함해 원하는 인물의 음성을
            </div>
            <div className={classes.gridStep}>인공지능에게 학습시켜보세요!</div>
          </div>
        </div>
      </Grid>
      <Grid className={classes.gridLeftAlign} item xs={12} sm={12} md={6}>
        <img style={{ maxWidth: "90%" }} src={stepOneImg} />
      </Grid>
      <Grid className={classes.gridRightAlign} item xs={12} sm={12} md={6}>
        <div className={classes.gridCenterAlign}>
          <div className={classes.gridStepHeader}>2단계: 커버 제작 기능</div>
          <div className={classes.gridStepContainer}>
            <div className={classes.gridStep}>커버곡과 음성만 선택하면 끝!</div>
          </div>
          <div className={classes.gridStepContainer}>
            <div className={classes.gridStep}>
              2분내로 원하는 음성으로 노래가 완성 됩니다
            </div>
          </div>
        </div>
      </Grid>
      <Grid className={classes.gridLeftAlign} item xs={12} sm={12} md={6}>
        <img style={{ maxWidth: "90%" }} src={stepTwoImg} />
      </Grid>
      <Grid className={classes.divider} item xs={12} sm={12} md={12} />
      <Grid className={classes.grid} item xs={12} sm={12} md={12}>
        <div className={classes.gridLastSectionContainer}>
          <div className={classes.lastSectionHeader}>소리소리AI만의</div>
          <div className={classes.lastSectionHeader}>
            더 특별한 인공지능 기술
          </div>
        </div>
      </Grid>
      <Grid className={classes.gridRightAlign} item xs={12} sm={12} md={6}>
        <div className={classes.gridCenterAlign}>
          <div className={classes.gridStepHeader}>AI를 이용한 오디오 믹싱</div>
          <div className={classes.gridStepContainer}>
            <div className={classes.gridStep}>
              <span className={classes.green}>소리소리AI</span>는 인공지능
              기술을 활용해
            </div>
            <div className={classes.gridStep}>
              오디오 엔지니어의 도움 없이도 믹싱을 처리합니다
            </div>
          </div>
          <div className={classes.gridStepContainer}>
            <div className={classes.gridStep}>이 모든 과정은 자동화 되어</div>
            <div className={classes.gridStep}>
              풍부하고 완성도 높은 음원을 얻을 수 있습니다
            </div>
          </div>
        </div>
      </Grid>
      <Grid className={classes.gridLeftAlign} item xs={12} sm={12} md={6}>
        <img style={{ marginTop: "2.5rem", maxWidth: "90%" }} src={mixingImg} />
      </Grid>
    </Grid>
  );
}

export default MainPage;
