import React, { useEffect } from "react";
import { useStyles } from "./TermsPage.styles";

function TermsPage() {
  const classes: any = useStyles();
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 10);
  }, []);
  return (
    <div className={classes.container}>
      <div className={classes.title}>Terms and Conditions</div>
      <div className={classes.text}>
        Before you start using our services, we want to make sure you understand
        the terms and conditions that govern your use of our website and
        software.
      </div>
      <div className={classes.text}>
        1. Acceptance of Terms: By accessing and using our platform, you
        acknowledge that you have read, understood, and agree to be bound by
        these terms and conditions. If you do not agree with these terms, please
        do not use our services.
      </div>
      <div className={classes.text}>
        1. User Accounts: To use our platform, you must create a user account.
        You are responsible for maintaining the confidentiality of your account
        information and for all activities that occur under your account.
      </div>
      <div className={classes.text}>
        1. Subscription Plans: We offer various subscription plans for our
        services. Each plan has different features and prices. You can choose
        the plan that best fits your needs.
      </div>
      <div className={classes.text}>
        1. Payment: You will be charged according to the subscription plan you
        select. Payment is due at the beginning of each billing cycle. We accept
        various payment methods, including credit card and PayPal.
      </div>
      <div className={classes.text}>
        1. Intellectual Property: All intellectual property rights in our
        platform, including software, content, and trademarks, are owned by us
        or our licensors. You may not copy, modify, distribute, or sell any part
        of our platform without our prior written consent.
      </div>
      <div className={classes.text}>
        1. Limitation of Liability: We will not be liable for any damages
        arising out of or in connection with the use of our platform, including
        but not limited to direct, indirect, incidental, or consequential
        damages.
      </div>
      <div className={classes.text}>
        1. Termination: We reserve the right to terminate your access to our
        platform at any time, without notice, if you violate these terms and
        conditions.
      </div>
      <div className={classes.text}>
        1. Changes to Terms: We may modify these terms and conditions at any
        time, without notice. Your continued use of our platform after such
        modifications will constitute your acceptance of the new terms.
      </div>
      <div className={classes.text}>
        If you have any questions or concerns about these terms and conditions,
        please contact us at support@sorisori.ai
      </div>
      <div className={classes.title}>이용 약관</div>
      <div className={classes.text}>
        서비스를 사용하기 시작하기 전에, 여러분이 저희 웹사이트와 소프트웨어
        사용에 적용되는 약관을 이해하고 동의하는지 확인하고자 합니다.
      </div>
      <div className={classes.text}>
        약관의 수락: 저희 플랫폼을 접속하고 사용함으로써, 여러분은 이 약관을
        읽었으며 이해하고 동의하며 이에 따라 제한되는 것을 인정합니다. 이 약관에
        동의하지 않는 경우 서비스를 사용하지 마시기 바랍니다.
      </div>
      <div className={classes.text}>
        사용자 계정: 저희 플랫폼을 사용하려면 사용자 계정을 생성해야 합니다.
        여러분은 계정 정보의 기밀성을 유지하고 계정 내에서 발생하는 모든 활동에
        대한 책임이 있습니다.
      </div>
      <div className={classes.text}>
        구독 요금제: 저희는 다양한 서비스 구독 요금제를 제공합니다. 각
        요금제에는 다른 기능과 가격이 포함됩니다. 여러분의 필요에 가장 적합한
        요금제를 선택하실 수 있습니다.
      </div>
      <div className={classes.text}>
        결제: 여러분은 선택한 구독 요금제에 따라 요금이 부과됩니다. 결제는 각
        결제 주기의 시작 시점에 이루어집니다. 네이버페이, 카카오페이, 신용카드
        및 PayPal과 같은 다양한 결제 방법을 사용할 수 있습니다.
      </div>
      <div className={classes.text}>
        지적 재산권: 소프트웨어, 콘텐츠 및 상표를 포함한 플랫폼의 모든 지적
        재산권은 저희 또는 라이선서에게 소유권이 있습니다. 저희의 사전 서면 동의
        없이는 플랫폼의 어떤 부분도 복사, 수정, 배포 또는 판매할 수 없습니다.
      </div>
      <div className={classes.text}>
        책임 제한: 저희 플랫폼 사용으로 인해 발생한 피해에 대해 저희는 어떠한
        책임도 지지 않습니다. 이로 인한 직접적, 간접적, 우발적 또는 결과적인
        피해를 포함합니다.
      </div>
      <div className={classes.text}>
        종료: 여러분이 이 약관을 위반하는 경우 언제든지 통보 없이 저희 플랫폼
        접근을 종료할 권리가 있습니다.
      </div>
      <div className={classes.text}>
        약관 변경: 우리는 언제든지 통보 없이 이 약관을 수정할 수 있습니다.
        이러한 수정 후 여러분이 플랫폼을 계속 사용하는 것은 새로운 약관에
        동의하는 것으로 간주됩니다.
      </div>
      <div className={classes.text}>
        이 약관에 대한 질문이 있으시면 support@sorisori.ai로 문의해주시기
        바랍니다.
      </div>
    </div>
  );
}

export default TermsPage;
