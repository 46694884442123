import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useStyles } from "./Layout.styles";

const Layout = (props: any) => {
  const classes: any = useStyles();
  return (
    <div className={classes.container}>
      <Header />
      <main className={classes.main}>{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
