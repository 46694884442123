import React, { useEffect } from "react";
import { useStyles } from "./PrivacyPolicyPage.styles";

function PrivacyPolicyPage() {
  const classes: any = useStyles();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 10);
  }, []);
  return (
    <div className={classes.container}>
      <div className={classes.title}>Privacy Policy</div>

      <div className={classes.sectionTitle}>Information We Collect </div>
      <li className={classes.text}>
        Personal Information: We may collect personal information such as names,
        email addresses, and other contact details when users register or
        interact with our app.
      </li>
      <li className={classes.text}>
        Usage Data: We collect data about how users interact with our app,
        including pages visited, features used, and other app activities. This
        data helps us improve the user experience.
      </li>
      <li className={classes.text}>
        Device Information: We may collect information about users' devices,
        including device type, operating system, unique device identifiers, and
        mobile network information.
      </li>

      <div className={classes.sectionTitle}>
        How We Use Collected Information
      </div>
      <div className={classes.text}>
        We use the collected information for various purposes, including but not
        limited to:
      </div>
      <li className={classes.text}>
        Providing and improving our app's functionality and user experience.
      </li>
      <li className={classes.text}>
        Sending important notifications and updates related to the app.
      </li>
      <li className={classes.text}>
        Personalizing user experiences by recommending content based on usage
        patterns.
      </li>
      <li className={classes.text}>
        Analyzing app usage data to enhance features and troubleshoot issues.
      </li>
      <li className={classes.text}>
        Ensuring the security and integrity of our app and user information.
      </li>

      <div className={classes.sectionTitle}>Data Sharing and Disclosure</div>
      <div className={classes.text}>We may share user information with:</div>
      <li className={classes.text}>
        Service Providers: We work with trusted third-party service providers to
        assist in app development, analytics, and user support. These providers
        are authorized to use user information only as necessary to perform
        their services for us.
      </li>
      <li className={classes.text}>
        Legal Compliance: We may share user information if required by law or to
        protect our rights, safety, or the rights, safety, or property of
        others.
      </li>

      <div className={classes.sectionTitle}>Data Security</div>
      <div className={classes.text}>
        We take appropriate security measures to protect user information from
        unauthorized access, alteration, disclosure, or destruction. However, no
        data transmission over the internet or data storage system can be
        guaranteed to be 100% secure.
      </div>

      <div className={classes.sectionTitle}>Your Choices</div>
      <li className={classes.text}>
        Opt-Out: Users can opt out of receiving promotional emails and
        notifications.
      </li>
      <li className={classes.text}>
        Access and Correction: Users can access, correct, or update their
        personal information by contacting us.
      </li>

      <div className={classes.sectionTitle}>Changes to this Privacy Policy</div>
      <div className={classes.text}>
        We reserve the right to update this Privacy Policy. Users will be
        notified of any material changes via email or within the app.
      </div>

      <div className={classes.sectionTitle}>Contact Us</div>
      <div className={classes.text}>
        If you have any questions or concerns about our Privacy Policy, please
        contact us at support@sorisori.ai
      </div>

      <div className={classes.title}>개인정보 방침</div>
      <li className={classes.text}>
        본 개인정보 처리방침은 Sorisori AI가 사용자 정보를 수집, 이용하고
        보호하는 방법에 대해 안내합니다. 본 웸사이트을 사용함으로써 여러분은 본
        방침에 기술된 관행에 동의하게 됩니다. Sorisori AI는 ｢개인정보 보호법｣
        제30조에 따라 이용자의 개인정보를 보호하고 이와 관련한 고충을 신속하고
        원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
        수립 및 공개합니다.
      </li>

      <div className={classes.sectionTitle}>수집하는 정보</div>
      <li className={classes.text}>
        개인정보: 사용자가 계정을 등록할시, 이메일 주소 등의 개인정보를 수집할
        수 있습니다.
      </li>
      <li className={classes.text}>
        사용 데이터: 사용자가 앱과 상호작용하는 방식에 대한 데이터를 수집하며,
        방문한 페이지, 사용한 기능, 그 외 앱 활동에 대한 데이터를 포함합니다.
        이러한 데이터는 사용자 경험을 개선하는 데 도움이 됩니다.
      </li>
      <li className={classes.text}>
        기기 정보: 사용자의 기기 종류, 운영 체제, 고유 기기 식별자 및 이동 통신
        네트워크 정보 등을 수집할 수 있습니다.
      </li>

      <div className={classes.sectionTitle}>수집한 정보의 이용 방법 </div>
      <div className={classes.text}>
        수집한 정보는 다음과 같은 목적으로 사용됩니다.
      </div>
      <li className={classes.text}>
        앱의 기능과 사용자 경험을 제공하고 개선하기 위해.{" "}
      </li>
      <li className={classes.text}>
        앱과 관련된 중요한 공지와 업데이트를 발송하기 위해.{" "}
      </li>
      <li className={classes.text}>
        사용자 활동 패턴을 기반으로 콘텐츠를 추천하여 개인화된 경험 제공하기
        위해.
      </li>
      <li className={classes.text}>
        앱 사용 데이터를 분석하여 기능을 향상시키고 문제를 해결하기 위해.{" "}
      </li>
      <li className={classes.text}>
        앱 및 사용자 정보의 보안과 무결성을 보장하기 위해.{" "}
      </li>

      <div className={classes.sectionTitle}>정보 공유 및 공개</div>
      <div className={classes.text}>
        사용자 정보를 다음과 같이 공유할 수 있습니다.
      </div>
      <li className={classes.text}>
        서비스 제공자: 앱 개발, 분석 및 사용자 지원을 도와주기 위해 신뢰할 수
        있는 제3의 서비스 제공자와 협력합니다. 이들 제공자는 저희를 위한 서비스
        수행에 필요한 범위 내에서만 사용자 정보를 사용할 수 있습니다.
      </li>
      <li className={classes.text}>
        법적 준수: 법률 준수를 위해 또는 저희의 권리, 안전, 다른 사람의 권리,
        안전 또는 재산을 보호하기 위해 사용자 정보를 공유할 수 있습니다.
      </li>

      <div className={classes.sectionTitle}>데이터 보안</div>
      <div className={classes.text}>
        사용자 정보를 무단 접근, 변경, 공개 또는 파괴로부터 보호하기 위해 적절한
        보안 조치를 취합니다. 그러나 인터넷을 통한 데이터 전송이나 데이터 저장
        시스템은 100% 보안이 보장되지 않을 수 있습니다.
      </div>

      <div className={classes.sectionTitle}>사용자 선택권</div>
      <li className={classes.text}>
        수신 거부: 사용자는 프로모션 이메일 및 알림 수신을 거부할 수 있습니다.
      </li>
      <li className={classes.text}>
        접근 및 수정: 사용자는 개인정보에 대한 접근, 수정 또는 업데이트를 요청할
        수 있습니다.
      </li>

      <div className={classes.sectionTitle}>본 개인정보 처리방침의 변경</div>
      <div className={classes.text}>
        본 방침을 업데이트할 권리를 보유하며, 중요한 변경 사항이 있을 경우
        사용자에게 이메일 또는 앱 내 공지를 통해 통지할 것입니다.
      </div>

      <div className={classes.sectionTitle}>문의하기</div>
      <div className={classes.text}>
        개인정보 처리방침에 대한 질문이나 문의사항이 있으시면
        support@sorisori.ai 로 연락 주시기 바랍니다.
      </div>
    </div>
  );
}

export default PrivacyPolicyPage;
