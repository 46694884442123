import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";
import { useStyles } from "./Footer.styles";

const Footer = () => {
  const navigate = useNavigate();
  const classes: any = useStyles();
  return (
    <Grid container className={classes.mainGrid} spacing={3}>
      <Grid className={classes.divider} item xs={12} sm={12} md={12} />
      <Grid className={classes.contentGrid} item xs={12} sm={12} md={12}>
        <div>
          <div>
            <span className={classes.companyInfo}>주식회사 소리소리AI</span>
            <span className={classes.companyInfo}>대표 : 신민제</span>
            <span className={classes.companyInfo}>
              사업자등록번호 : 610-86-36823
            </span>
            <span className={classes.companyInfo}>
              경기도 화성시 메타폴리스로 42, 9층 902호 엘14
            </span>
            <span className={classes.companyInfo}>TEL : 070-8018-9818</span>
          </div>
          <div>
            <span className={classes.companyInfo}>
              Copyright © 2023 Sorisori LLC
            </span>
          </div>
        </div>
        <div>
          <div
            className={classes.content}
            onClick={() =>
              window.open("https://discord.gg/AVREYrRZv4", "_blank")
            }
          >
            문의 사항
          </div>
          <div className={classes.content} onClick={() => navigate("/terms")}>
            이용 약관
          </div>
          <div
            className={classes.content}
            onClick={() => navigate("/privacy-policy")}
          >
            개인정보 처리방침
          </div>
          <div
            className={classes.content}
            onClick={() => navigate("/refund-policy")}
          >
            환불 규졍
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Footer;
