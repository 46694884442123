import React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar } from "@mui/material";

import logo_white from "../../img/logo_white.png";
import styles from "./Header.module.css";

const Header = () => {
  const navigate = useNavigate();
  return (
    <AppBar className={styles.appBar}>
      <img
        onClick={() => navigate("/?navigateTo=top")}
        className={styles.logo}
        src={logo_white}
        alt="Logo"
      />
      <div
        onClick={() => navigate("/?navigateTo=first-divider")}
        className={styles.navButton}
      >
        기술 설명
      </div>
      <div
        onClick={() =>
          window.open("https://app.sorisori.ai/subscription", "_blank")
        }
        className={styles.navButton}
      >
        요금제
      </div>
    </AppBar>
  );
};

export default Header;
