import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "36rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
    },
    title: {
      marginTop: "2rem",
      color: "#fff",
      fontSize: "2rem",
      marginBottom: "1rem",
    },
    sectionTitle: {
      marginLeft: "1.8rem",
      alignSelf: "flex-start",
      color: "#fff",
      fontWeight: "700",
      fontSize: "1rem",
    },
    text: {
      color: "#fff",
      fontSize: "1rem",
      maxWidth: "90%",
      marginBottom: "1rem",
      marginLeft: "1.8rem",
      alignSelf: "flex-start",
    },
  })
);
export { useStyles };
